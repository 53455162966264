import logger from '@libs/log';
import { router } from './routes';

const redirectTo = (path: string) => {
  router.navigate(path).catch(() => {
    logger.error('Failed to redirect to: ' + path);
  });
  return null;
};

export { redirectTo };
