import logger from '@libs/log';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import arTranslations from './languages/ar';
import enTranslations from './languages/en';
import esTranslations from './languages/es';
import frTranslations from './languages/fr';
import afgTranslations from './languages/fr-AF';
import psTranslations from './languages/ps';
import ptTranslations from './languages/pt';
import rnTranslations from './languages/rn';
import swTranslations from './languages/sw';
import tetTranslations from './languages/tdt';

export const isRTL = () => {
  return i18n.dir() === 'rtl';
};

await i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      detection: {
        order: ['localStorage'],
        lookupLocalStorage: 'language',
      },
      fallbackLng: 'en',
      saveMissing: false,
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react!!
        prefix: '{{{',
        suffix: '}}}',
      },
      react: {
        bindI18n: 'languageChanged',
        bindI18nStore: 'added removed',
        nsMode: 'default',
      },
      resources: {
        es: {
          translation: esTranslations,
        },
        'es-419': {
          translation: esTranslations,
        },
        en: {
          translation: enTranslations,
        },
        fr: {
          translation: frTranslations,
        },
        sw: {
          translation: swTranslations,
        },
        pt: {
          translation: ptTranslations,
        },
        ar: {
          translation: arTranslations,
        },
        tet: {
          translation: tetTranslations,
        },
        rn: {
          translation: rnTranslations,
        },
        'fa-AF': {
          translation: afgTranslations,
        },
        ps: {
          translation: psTranslations,
        },
      },
    },
    (err) => {
      if (err) {
        logger.error(err);
        return;
      }
    }
  )
  .catch((e: unknown) => {
    logger.error(e);
  });

export function i18nCText(value: string) {
  if (value) {
    return i18n.t(value, { keySeparator: ':::', nsSeparator: '|||' });
  }
  return '';
}

export default i18n;
