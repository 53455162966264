import env from '@config/env';
import i18n from '@libs/i18n/I18n';
import { useRollbar } from '@rollbar/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { error } from '../../../assets/images';
import { translate } from '../../../state/utils/helper';
import NavBar from '../NavigationBar/NavBar';
import ErrorPage from './ErrorPage';
import LayoutBody from './LayoutBody';

const ErrorBoundary = () => {
  const errorFromRouter = useRouteError();
  const rollbar = useRollbar();

  useEffect(() => {
    if (errorFromRouter) {
      rollbar.error(errorFromRouter);
      if (env.PROD) {
        setTimeout(() => window.open('/', '_self'), 2000);
      }
    }
  }, [errorFromRouter]);

  return (
    <>
      <NavBar language={i18n.language} />
      <LayoutBody>
        <ErrorPage errorMessage={translate('errors.error404Message')} errorImage={error[500]} />;
      </LayoutBody>
    </>
  );
};

export default ErrorBoundary;
