import ProjectsComponent from '../components/Projects/List';
import ProjectReport from '../components/Projects/Report';
import ReviewIdeaComponent from '../components/Projects/Review/Idea';
import ProjectViewComponent from '../components/Projects/View';
import ConfirmAccountComponent from '../components/Users/Auth/ConfirmAccount';
import MagicLinkLogin from '../components/Users/Auth/MagicLinkLogin';
import SignInComponent from '../components/Users/Auth/SignIn';
import SignUpComponent from '../components/Users/Auth/SignUp';
import VerifyToken from '../components/Users/Auth/VerifyToken';
import SettingsComponent from '../components/Users/Settings';
import ProjectPreviewContainer from '../containers/Projects/ProjectPreviewContainer';
import TakeEvaluationContainer from '../containers/Projects/TakeEvaluationContainer';
import TakeProjectContainer from '../containers/Projects/TakeProjectContainer';
import C404 from '../containers/UI/C404';
import NotificationsAllContainer from '../containers/UI/NotificationsAllContainer';
import AssessmentsContainer from '../containers/Users/Assessments/AssessmentsContainer';
import ForgotPasswordContainer from '../containers/Users/ForgotPasswordContainer';
import NotesContainer from '../containers/Users/NotesContainer';
import ProfileContainer from '../containers/Users/ProfileContainer';
import RecommendationsContainer from '../containers/Users/Recommendations/RecommendationsContainer';
import RedemptionsContainer from '../containers/Users/Redemptions/RedemptionsContainer';
import ResetPasswordContainer from '../containers/Users/ResetPasswordContainer';

import ErrorBoundary from '@components/UI/Layout/ErrorBoundary';
import ScrollToTop from '@components/UI/ScrollToTop';
import env from '@config/env';
import ReactNotificationsComponent from 'react-notifications-component';
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import ListWorkspaces from '../components/Workspaces/ListWorkspaces';
import FAQ from '../containers/Users/Extras/FAQ';
import Protected from './protected';
import Public from './public';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <>
          <ReactNotificationsComponent />
          <ScrollToTop>
            <Outlet />
          </ScrollToTop>
        </>
      }
      errorElement={env.PROD ? <ErrorBoundary /> : null}
    >
      {/* These routes are private and they are not accessible until you login */}
      <Route element={<Protected />}>
        <Route index path="/" element={<ListWorkspaces />} />
        <Route
          path="/workspace/:workspaceId/challenge/:projectId/report"
          element={<ProjectReport />}
        />
        <Route
          path="/workspace/:workspaceId/challenge/:projectId/:stageId/:pageId/:stagePageName"
          element={<TakeProjectContainer />}
        />
        <Route path="/assessment/:id/preview" element={<ProjectPreviewContainer />} />
        <Route path="/evaluations/code/:code" element={<TakeEvaluationContainer />} />
        <Route path="/evaluations/:id" element={<TakeEvaluationContainer />} />
        <Route path="/assessments" element={<AssessmentsContainer />} />
        <Route path="/workspaces" element={<ListWorkspaces />} />
        <Route path="/settings/:section" element={<SettingsComponent />} />
        <Route path="/settings" element={<SettingsComponent />} />
        <Route path="/me" element={<ProfileContainer />} />
        <Route path="/notifications" element={<NotificationsAllContainer />} />
        <Route path="/notes" element={<NotesContainer />} />
      </Route>

      {/* These routes are public and they will not be accessible if you are logged in */}
      <Route element={<Public />}>
        <Route path="/sign_in" element={<SignInComponent />} />
        <Route path="/sign_up" element={<SignUpComponent />} />
        <Route path="/forgot_password" element={<ForgotPasswordContainer />} />
        <Route path="/magic/:token" element={<MagicLinkLogin />} />
        <Route path="/magic" element={<MagicLinkLogin />} />
        <Route path="/confirm/:token" element={<ConfirmAccountComponent />} />
        <Route path="/confirm" element={<ConfirmAccountComponent />} />
        <Route path="/reset_password/:token" element={<ResetPasswordContainer />} />
        <Route path="/reset_password" element={<ResetPasswordContainer />} />
      </Route>

      {/* We have put this out of public routes because this is being used to log user in from sso and then redirect to the challenge */}
      <Route path="/sso/verify" element={<VerifyToken />} />

      <Route path="/faq" element={<FAQ />} />
      <Route
        path="/workspace/:workspaceId/challenge/:projectId/idea/:userAnswerId"
        element={<ReviewIdeaComponent />}
      />
      <Route
        path="/workspace/:workspaceId/challenge/:projectId/:projectName"
        element={<ProjectViewComponent />}
      />
      <Route path="redemptions" element={<RedemptionsContainer />} />
      <Route path="/recommendations" element={<RecommendationsContainer />} />
      <Route path="/workspace/:workspaceId" element={<ProjectsComponent />} />
      <Route path="*" element={<C404 />} />
    </Route>
  )
);

const Index = () => {
  return <RouterProvider router={router} />;
};

export { router };

export default Index;
