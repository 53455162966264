import { CardReviewAnswer } from '@app/@types/redux/answer';
import {
  Evaluation,
  FeedbackAnswer,
  IAnswers,
  Project,
  ProjectFilters,
  ProjectReducer,
  Restriction,
  UserAnswerAnswer,
} from '@app/@types/redux/project';
import { redirectTo } from '@app/routes/helpers';
import { PAGE_ELEMENT } from '@libs/constants/constants';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  canGoToEvaluationPage,
  canGoToPage,
  getActiveStage,
  getAnswers,
  getAutoPopupModal,
  getCurrentEvaluation,
  getCurrentProject,
  getEvaluationAnswers,
  getPageFromStageId,
  notAnsweredAlert,
  pageBelongsToEvaluation,
  pageBelongsToProject,
} from '@state/selectors/projects';
import Api from '@state/utils/Api';
import { isLoggedIn } from '@utils/helpers/auth';
import { PURGE } from 'redux-persist';
import snakecaseKeys from 'snakecase-keys';
import { RootState } from '../store';
import { fetchUserWorkspace } from './workspace.slice';

const initialStateProject: Project = {
  id: '',
  type: '',
  attributes: {
    applicants: 0,
    beginDate: '',
    applicationDeadline: '',
    applicationsLeft: 0,
    company: {
      id: '',
      name: '',
      bio: '',
      createdAt: '',
      updatedAt: '',
      imageData: '',
    },
    createdAt: '',
    description: '',
    endDate: '',
    id: '',
    imageData: '',
    isActive: false,
    isDemo: false,
    isPublished: false,
    name: '',
    projectStages: [],
    reportFormat: '',
    shortDescription: '',
    showReport: false,
    showReportDate: '',
    softSkills: [],
    status: '',
    submissionDeadline: '',
    workspaceId: '',
    workspaceName: '',
    options: {
      type: '',
      creationOptions: {
        creatorId: '',
        workspaceId: '',
        challengeName: '',
        challengeDescription: '',
        challengeImage: '',
        beginDate: '',
        endDate: '',
        resources: [],
        evaluationId: '',
        projectId: '',
        projectData: {
          name: '',
          image: '',
          description: '',
          beginDate: '',
          endDate: '',
          projectUrl: '',
          resources: [],
          evaluationId: '',
          id: '',
          pages: {},
          launchPage: [],
          competencyPoints: 0,
        },
        stageJson: [],
        wizardStructure: [],
        status: '',
        id: '',
      },
      sdg: [],
      images: {},
      sections: [],
      overviewContent: [],
      sheetData: {
        Timeline: [],
      },
      resources: [],
      testProjectToken: '',
    },
  },
};

const initialState: ProjectReducer = {
  feedbacks: {},
  currentProject: {
    userProject: {
      id: '',
      type: '',
      attributes: {
        updatedAt: '',
        state: '',
        reportLink: '',
        progress: 0,
        profanity: false,
        project: {
          id: '',
          name: '',
          description: '',
          beginDate: '',
          endDate: '',
          workspaceId: '',
          workspaceName: '',
          image: '',
          creatorName: '',
        },
        userNote: {
          id: '',
          type: '',
          attributes: {
            text: '',
            title: '',
            updatedAt: '',
          },
        },
        user: {
          id: '',
          email: '',
          avatarUrl: '',
          fullName: '',
        },
        workspace: {
          id: '',
          name: '',
        },
        currentStage: {
          id: '',
          name: '',
        },
      },
    },
    project: initialStateProject,
    reviewStatus: {},
    finalSubmissions: [],
    ideas: [],
  },
  currentStageId: '',
  activeStageId: '',
  currentPageId: '',
  currentEvaluationPageId: '',
  answers: {},
  answersBacklog: {},
  evaluationAnswers: {},
  evaluationAnswersBacklog: {},
  filters: {
    orderBy: 'begin_date asc',
    softSkillsIdIn: [],
    companyIdIn: [],
    subcategoriesIdIn: [],
    applicationsLeftGteq: null,
    isActive: 'active',
    sortDirection: '',
  },
  nextButtonValidate: false,
  projects: [],
  totalProjects: 0,
  popupFlag: {
    videoPopup: false,
    instructionPopup: false,
    examplePopup: false,
    resourcesPopup: false,
    noteSidePane: false,
  },
  restrictions: [],
  notAnsweredModal: false,
  currentEvaluation: {
    evaluation: {
      attributes: {
        code: '',
        createdAt: '',
        creatorId: '',
        description: '',
        id: '',
        image: '',
        name: '',
        options: {
          afterQuiz: {
            bad: {
              selected: [],
              message: '',
              videoUrl: '',
            },
            good: {
              selected: [],
              message: '',
              videoUrl: '',
            },
            threshold: 0,
          },
          creatorId: 0,
          questions: [],
          quizDescription: '',
          quizImage: '',
          quizName: '',
          resultsDisplay: '',
          workspaceId: '',
        },
        pages: [],
        workspaceId: 0,
      },
      id: '',
      type: '',
    },
    userEvaluation: {
      id: '',
      type: '',
      attributes: {
        createdAt: '',
        evaluationId: '',
        evaluationCode: '',
        results: {
          result: '',
          resultHuman: '',
        },
      },
    },
  },
  currentUserAnswer: {
    id: '',
    type: '',
    attributes: {
      createdAt: '',
      aiGrade: 0,
      answer: {},
      id: '',
      pageElement: {
        id: '',
        type: '',
        attributes: {
          options: {
            value: '',
            keys: {},
          },
          position: 0,
          createdAt: '',
          dependeeKey: null,
          dependees: [],
          dependentPageElementId: null,
          elementType: PAGE_ELEMENT.NULL,
          factor: [],
          file: null,
          fileUrl: null,
          id: '',
          image: null,
          imageUrl: null,
          imageUrlOriginal: null,
          imageUrlTiny: null,
          key: null,
          pageId: 0,
          required: false,
          reviewable: false,
          updatedAt: '',
        },
      },
      reportFlags: {
        adultOnly: '',
        allegation: '',
        bigotry: '',
        criminalActivity: '',
        disturbing: '',
        externalContact: '',
        mentalHealth: '',
        personalAttack: '',
        profanity: '',
        replaceText: '',
        sexualAdvances: '',
      },
      reviewCount: 0,
      statistics: {
        actionNeeded: false,
        aiCompetencyScores: [],
        aiScores: [],
        attachments: 0,
        competencyScores: [],
        computedAiScore: 0,
        computedExpertScore: 0,
        computedPeerScore: 0,
        computedScore: 0,
        computedSelfScore: 0,
        expertCompetencyScores: [],
        expertScores: [],
        peerCompetencyScores: [],
        peerScores: [],
        scores: [],
        selectedReviewTypePreference: '',
        selfCompetencyScores: [],
        selfScores: [],
      },
      userId: '',
    },
  },
  previewProject: initialStateProject,
  reviewable: {
    items: [],
    review: {},
    reviewPageElements: [],
  },
  reviewing: {
    reviewingUserAnswerId: '',
    reviewingFrom: '',
  },
  reviews: {},
  allowCopyPaste: false,
};

export const fetchFeedback = createAsyncThunk(
  'project/fetchFeedback',
  async ({ projectId, pageElementId }: { projectId: string; pageElementId: string }) => {
    const response = await Api.getFeedback(projectId, pageElementId);
    return {
      data: response.data.data,
      pageElementId,
    };
  }
);

export const applyToProject = createAsyncThunk(
  'project/applyToProject',
  async ({
    projectId,
    token,
  }: {
    projectId: string;
    token: string | null; // token is optional, string | undefined is not the same as string | null
  }) => {
    const response = await Api.applyToProject(projectId, token);
    return response.data.data;
  }
);

export const fetchUserProject = createAsyncThunk(
  'project/fetchUserProject',
  async ({ id }: { id: string }) => {
    const response = await Api.getUserProject(id);
    return response.data;
  }
);

export const fetchCurrentProject = createAsyncThunk(
  'project/fetchCurrentProject',
  async ({ id }: { id: string }) => {
    const response = await Api.getProject(id);
    return response.data.data;
  }
);

export const fetchProjects = createAsyncThunk(
  'project/fetchProjects',
  async (
    {
      workspaceId,
      page = 1,
      perPage = 9,
    }: {
      workspaceId: string;
      page: number;
      perPage: number;
    },
    { getState }
  ) => {
    const wkId = workspaceId;
    const isSignedIn = isLoggedIn();
    const state = getState() as RootState;
    const filters = JSON.stringify(snakecaseKeys(state.projects.filters));
    const response = await Api.getProjects(filters, page, perPage, wkId, isSignedIn);
    return response.data;
  }
);

export const fetchProject = createAsyncThunk(
  'project/fetchProject',
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch }
  ) => {
    const response = await Api.getProject(id);
    const isSignedIn = isLoggedIn();
    if (isSignedIn && response.data.data.attributes.workspaceId) {
      await dispatch(
        fetchUserWorkspace({
          workspaceId: response.data.data.attributes.workspaceId,
        })
      );
    }
    if (isSignedIn && response.data.data.id) {
      await dispatch(fetchUserProject({ id: response.data.data.id }));
    }
    return response.data;
  }
);

const newAnswers = (state: ProjectReducer, entityId: string): IAnswers => {
  if (!state.currentProject.project.id || !entityId) {
    return state.answers;
  }
  if (state.currentProject.project.id === entityId) {
    // if it's the same project than before, then use the same answers
    return state.answers;
  }
  // if it isn't the same project that before then restore answers from backlog
  const backlog = state.answersBacklog;
  return backlog[entityId];
};

const newEvaluationAnswers = (state: ProjectReducer, entityId: string): IAnswers => {
  if (!state.currentProject.project.id || !entityId) {
    return state.evaluationAnswers;
  }
  if (state.currentEvaluation.evaluation.id === entityId) {
    // if it's the same project than before, then use the same answers
    return state.evaluationAnswers;
  }
  // if it isn't the same project that before then restore answers from backlog
  const backlog = state.answersBacklog;
  return backlog[entityId];
};

const newBacklogAnswers = (state: ProjectReducer, entityId: string): Record<string, IAnswers> => {
  if (!state.currentProject.project.id || !entityId) {
    return state.answersBacklog;
  }
  if (state.currentProject.project.id === entityId) {
    // if it's the same project than before, then use the same answersBacklog
    return state.answersBacklog;
  }
  // if it is'nt the same project than before then save current answers in backlog
  const newAnswerBacklog = state.answersBacklog;
  newAnswerBacklog[state.currentProject.project.id] = state.answers;
  return newAnswerBacklog;
};

const newBacklogEvaluationAnswers = (
  state: ProjectReducer,
  entityId: string
): Record<string, IAnswers> => {
  if (!state.currentProject.project.id || !entityId) {
    return state.evaluationAnswersBacklog;
  }
  if (state.currentEvaluation.evaluation.id === entityId) {
    // if it's the same project than before, then use the same answersBacklog
    return state.answersBacklog;
  }
  // if it is'nt the same project than before then save current answers in backlog
  const newAnswerBacklog = state.answersBacklog;
  newAnswerBacklog[state.currentEvaluation.evaluation.id] = state.evaluationAnswers;
  return newAnswerBacklog;
};

export const submitAnswers = createAsyncThunk(
  'project/submitAnswers',
  async (
    {
      showPageLoader,
      hidePageLoader,
    }: {
      showPageLoader: () => void;
      hidePageLoader: () => void;
    },
    { dispatch, rejectWithValue, getState }
  ) => {
    const rootState = getState() as RootState;
    const state = rootState.projects;
    const { currentPageId } = state;
    const available = canGoToPage(state, currentPageId, 'submit');
    if (typeof available === 'string') {
      notAnsweredAlert(available);
      return rejectWithValue('Not answered');
    }
    showPageLoader();
    const stateAnswers = getAnswers(state);
    const currentProject = getCurrentProject(state);
    const { id: projectId } = currentProject;
    const activeStageId = getActiveStage(state)?.id;
    const userId = rootState.users.user.id;
    const workspaceId = rootState.workspaces.currentWorkspace.id;
    const answers = Object.keys(stateAnswers).map((pageElementId) => ({
      user_id: userId,
      page_element_id: pageElementId,
      answer: stateAnswers[pageElementId]?.answer,
    }));

    if (!activeStageId) {
      hidePageLoader();
      return rejectWithValue('Not answered');
    }

    const response = await Api.sendAnswers(projectId, activeStageId, answers);

    dispatch(updateRestrictions());
    const { currentStageId } = state;
    const resCurrentStageId =
      response.data.userProject.data.attributes.currentStage.id || currentStageId;
    const resProgress = response.data.userProject.data.attributes.progress || 0;
    dispatch(
      switchProjectStage({
        id: resCurrentStageId,
        pageId: currentPageId,
      })
    );
    // Shift this code to the component
    // redirectToChallengePage(
    //   {
    //     id: projectId,
    //   },
    //   {
    //     id: stage.id,
    //     stageName: stage.attributes.stageName,
    //     stageType: stage.attributes.stageType,
    //   },
    //   page,
    //   workspaceId,
    // );
    hidePageLoader();
    if (resProgress === 1) {
      redirectTo(`/workspace/${workspaceId}/challenge/${projectId}/report`);
    }
    return response.data;
  }
);

export const submitFeedback = createAsyncThunk(
  'project/submitFeedback',
  async (
    {
      answer,
      pageElementId,
    }: {
      answer: FeedbackAnswer;
      pageElementId: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const project = getCurrentProject(state.projects);
    const userId = state.users.user.id;
    const { id: projectId } = project;
    const answers = {
      user_id: userId,
      page_element_id: pageElementId,
      answer,
    };
    const response = await Api.sendFeedback(projectId, pageElementId, answers);
    return response.data;
  }
);

export const submitEvaluationAnswers = createAsyncThunk(
  'project/submitEvaluationAnswers',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const stateAnswers = getEvaluationAnswers(state.projects);
    const evaluation = getCurrentEvaluation(state.projects);
    const { id: evaluationId } = evaluation;
    const answers = Object.keys(stateAnswers).map((pageElementId) => ({
      page_element_id: pageElementId,
      answer: stateAnswers[pageElementId]?.answer,
    }));
    const response = await Api.sendEvaluationAnswers(evaluationId, answers);
    return response.data.data;
  }
);

export const fetchUserEvaluation = createAsyncThunk(
  'project/fetchUserEvaluation',
  async ({ id }: { id: string }) => {
    const response = await Api.getUserEvaluation(id);
    return response.data;
  }
);

export const resetUserEvaluation = createAsyncThunk(
  'project/resetUserEvaluation',
  async ({ id }: { id: string }) => {
    const response = await Api.resetUserEvaluation(id);
    return response.data.userEvaluation.data;
  }
);

export const fetchEvaluation = createAsyncThunk(
  'project/fetchEvaluation',
  async ({ id }: { id: string }) => {
    const response = await Api.getEvaluation(id);
    return response.data.data;
  }
);

export const fetchEvaluationByCode = createAsyncThunk(
  'project/fetchEvaluationByCode',
  async ({ code }: { code: string }) => {
    const response = await Api.getEvaluationByCode(code);
    return response.data.data;
  }
);

export const fetchUserAnswers = createAsyncThunk(
  'project/fetchUserAnswers',
  async ({ projectId, userAnswerId }: { projectId: string; userAnswerId: string }) => {
    const response = await Api.getUserAnswer(projectId, userAnswerId);
    return response.data;
  }
);

export const resetUserProject = createAsyncThunk(
  'project/resetUserProject',
  async (
    {
      workspaceId,
      projectId,
    }: {
      workspaceId: string;
      projectId: string;
    },
    { dispatch }
  ) => {
    const response = await Api.resetUserProject(projectId);
    dispatch(switchProjectStage({ id: projectId, pageId: '' }));
    redirectTo(`/workspace/${workspaceId}/challenge/${projectId}/reset`);
    return response.data.data;
  }
);

export const submitReview = createAsyncThunk(
  'project/submitReview',
  async ({
    userAnswerId,
    userAnswer,
    projectId,
    reviewingFrom,
    successCallback,
  }: {
    userAnswerId: string;
    userAnswer: CardReviewAnswer;
    projectId: string;
    reviewingFrom: string;
    successCallback: () => void;
    errorCallback: () => void; // to be used in case of error
  }) => {
    const response = await Api.sendReview(projectId, userAnswerId, userAnswer, reviewingFrom);
    successCallback();
    return response.data;
  }
);

export const fetchReviewable = createAsyncThunk(
  'project/fetchReviewable',
  async ({
    projectId,
    userAnswerId,
    userId,
  }: {
    projectId: string;
    userAnswerId: string;
    userId: string;
  }) => {
    const response = await Api.getReviewable(projectId, userAnswerId, userId);
    return response.data;
  }
);

export const fetchToReviewIdea = createAsyncThunk(
  'project/fetchToReviewIdea',
  async ({ projectId, pageElementId }: { projectId: string; pageElementId: string }) => {
    const response = await Api.getToReviewAnswer(projectId, pageElementId);
    return response.data;
  }
);

export const fetchReviewStatus = createAsyncThunk(
  'project/fetchReviewStatus',
  async ({ projectId }: { projectId: string }) => {
    const response = await Api.getReviewStatus(projectId);
    return response.data;
  }
);

export const fetchFinalSubmissions = createAsyncThunk(
  'project/fetchFinalSubmissions',
  async ({
    projectId,
    page = 1,
    perPage = 20,
  }: {
    projectId: string;
    page: number;
    perPage: number;
  }) => {
    const response = await Api.getFinalSubmissions(projectId, page.toString(), perPage.toString());
    return response.data;
  }
);

export const fetchIdeas = createAsyncThunk(
  'project/fetchIdeas',
  async ({
    projectId,
    page = 1,
    perPage = 20,
  }: {
    projectId: string;
    page: number;
    perPage: number;
  }) => {
    const response = await Api.getIdeas(projectId, page.toString(), perPage.toString());
    return response.data;
  }
);

const fetchEvaluationFulfilled = (state: ProjectReducer, action: PayloadAction<Evaluation>) => {
  state.currentEvaluation.evaluation = action.payload;
  state.evaluationAnswers = newEvaluationAnswers(state, action.payload.id);
  state.evaluationAnswersBacklog = newBacklogEvaluationAnswers(state, action.payload.id);
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    answerProject(
      state,
      action: PayloadAction<{
        peId: string;
        type: PAGE_ELEMENT;
        payload: UserAnswerAnswer;
      }>
    ) {
      const { peId, type, payload } = action.payload;
      state.answers = {
        ...state.answers,
        [peId]: {
          type,
          answer: payload,
        },
      };
    },
    answerEvaluation(
      state,
      action: PayloadAction<{
        peId: string;
        type: PAGE_ELEMENT;
        payload: UserAnswerAnswer;
      }>
    ) {
      const { peId, type, payload } = action.payload;
      state.evaluationAnswers = {
        ...state.evaluationAnswers,
        [peId]: {
          type,
          answer: payload,
        },
      };
    },
    nextValidate(state, action: PayloadAction<boolean>) {
      state.nextButtonValidate = action.payload;
    },
    updateFilters(state, action: PayloadAction<ProjectFilters>) {
      state.filters = action.payload;
    },
    togglePopupFlag(
      state,
      action: PayloadAction<{
        flag: string;
        flagValue: boolean;
      }>
    ) {
      const { flag, flagValue } = action.payload;
      state.popupFlag = {
        ...state.popupFlag,
        [flag]: flagValue,
      };
    },
    toggleVideoPopup(state, action: PayloadAction<boolean>) {
      state.popupFlag = {
        ...state.popupFlag,
        videoPopup: action.payload,
      };
    },
    toggleInstructionPopup(state, action: PayloadAction<boolean>) {
      state.popupFlag = {
        ...state.popupFlag,
        instructionPopup: action.payload,
      };
    },
    toggleExamplePopup(state, action: PayloadAction<boolean>) {
      state.popupFlag = {
        ...state.popupFlag,
        examplePopup: action.payload,
      };
    },
    toggleResourcesPopup(state, action: PayloadAction<boolean>) {
      state.popupFlag = {
        ...state.popupFlag,
        resourcesPopup: action.payload,
      };
    },
    toggleNoteSidePane(state, action: PayloadAction<boolean>) {
      state.popupFlag = {
        ...state.popupFlag,
        noteSidePane: action.payload,
      };
    },
    toggleProjectHeaderPopup(state, action: PayloadAction<string>) {
      const autoPopup = getAutoPopupModal(state, action.payload);
      const currentStageId = state.currentStageId ? state.currentStageId : false;
      const activeStageId = state.activeStageId ? state.activeStageId : false;
      const currentStageIdInt = currentStageId ? parseInt(currentStageId.toString(), 10) : false;
      const activeStageIdInt = activeStageId ? parseInt(activeStageId, 10) : false;
      if (currentStageIdInt === activeStageIdInt) {
        state.popupFlag = {
          ...state.popupFlag,
          videoPopup: false,
          instructionPopup: false,
          examplePopup: false,
        };
        if (autoPopup === 'video') {
          state.popupFlag = {
            ...state.popupFlag,
            videoPopup: true,
          };
        }
        if (autoPopup === 'instruction') {
          state.popupFlag = {
            ...state.popupFlag,
            instructionPopup: true,
          };
        }
        if (autoPopup === 'example') {
          state.popupFlag = {
            ...state.popupFlag,
            examplePopup: true,
          };
        }
      }
    },
    switchProjectPage(state, action: PayloadAction<string>) {
      const pageId = action.payload;
      const available = canGoToPage(state, pageId);
      if (typeof available !== 'string') {
        state.currentPageId = pageId;
        window.scrollTo(0, 0);
      } else {
        notAnsweredAlert(available);
      }
    },
    switchEvaluationPage(state, action: PayloadAction<string>) {
      const pageId = action.payload;
      const available = canGoToEvaluationPage(state, pageId);
      if (available) {
        state.currentEvaluationPageId = pageId;
        window.scrollTo(0, 0);
      } else {
        notAnsweredAlert();
      }
    },

    switchProjectStage(
      state,
      action: PayloadAction<{
        id: string; // stageId
        pageId: string; // pageId
        mode?: string; // mode
      }>
    ) {
      const page = getPageFromStageId(state, action.payload.id, action.payload.pageId);
      const available = canGoToPage(state, page?.id ?? '', action.payload.mode);
      if (typeof available !== 'string') {
        state.activeStageId = action.payload.id;
        state.currentPageId = page?.id ?? '';
        window.scrollTo(0, 0);
      } else {
        notAnsweredAlert(available);
      }
    },
    switchProjectActiveStage(
      state,
      action: PayloadAction<{
        id: string;
        pageId: string;
      }>
    ) {
      const available = canGoToPage(state, action.payload.pageId);
      if (typeof available !== 'string') {
        state.activeStageId = action.payload.id;
        state.currentPageId = action.payload.pageId;
        window.scrollTo(0, 0);
      } else {
        notAnsweredAlert(available);
      }
    },
    hideNotAnsweredModal(state) {
      state.notAnsweredModal = false;
    },
    setRestrictions(state, action: PayloadAction<{ restrictions: Restriction[] }>) {
      state.restrictions = action.payload.restrictions;
    },
    updateRestrictions(state) {
      const {
        currentProject: {
          project: {
            attributes: { projectStages },
          },
          userProject,
        },
        currentStageId,
      } = state;
      // Initialize restrictions array
      const restrictions: Restriction[] = [];
      // Initialize stageAllowState to true to allow the first stage
      let stageAllowState = true;
      let flag = true;
      projectStages.forEach((stage) => {
        const pages: Restriction['pages'] = [];
        stageAllowState = flag;
        if (stage.attributes.id === currentStageId) {
          flag = false;
        }
        stage.attributes.pages.forEach((page) => {
          const pageAllowState = true;
          const pageElements: Restriction['pages'][0]['pageElements'] = [];
          page.attributes.pageElements.forEach((pe) => {
            pageElements.push({
              pageElementId: pe.attributes.id,
              disabled: userProject.attributes.state === 'Finished' ? true : flag,
            });
          });
          pages.push({
            pageId: page.attributes.id,
            pageElements,
            allow: pageAllowState,
          });
        });
        restrictions.push({
          stageId: stage.attributes.id,
          pages,
          allow: stageAllowState,
        });
      });
      state.restrictions = restrictions;
    },
    resetProjectsFilters(state) {
      state.filters = initialState.filters;
    },
    resetEvaluationOrProject(state, action: PayloadAction<string>) {
      const entityType = action.payload;
      if (entityType === 'project' && state.currentPageId && state.currentProject.project.id) {
        if (!pageBelongsToProject(state.currentPageId, state.currentProject.project)) {
          state.currentPageId = '';
        }
      } else if (
        entityType === 'evaluation' &&
        state.currentPageId &&
        state.currentEvaluation.evaluation.id
      ) {
        if (!pageBelongsToEvaluation(state.currentPageId, state.currentEvaluation.evaluation)) {
          state.currentPageId = '';
        }
      }
    },
    updateReviewingFrom(
      state,
      action: PayloadAction<{
        reviewingFrom: string;
        reviewingUserAnswerId: string;
      }>
    ) {
      const tempReviewingFrom = ['direct', 'ideaboard', 'allotted'].includes(
        action.payload.reviewingFrom
      )
        ? action.payload.reviewingFrom
        : 'direct';
      state.reviewing = {
        reviewingUserAnswerId: action.payload.reviewingUserAnswerId,
        reviewingFrom: tempReviewingFrom,
      };
    },
    setAllowCopyPaste(state, action: PayloadAction<boolean>) {
      state.allowCopyPaste = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addCase(fetchFeedback.fulfilled, (state, action) => {
      if (!action.payload.data.length) {
        return;
      }
      state.feedbacks = {
        ...state.feedbacks,
        [action.payload.pageElementId]: action.payload.data,
      };
    });
    builder.addCase(applyToProject.fulfilled, (state, action) => {
      state.currentProject.userProject = action.payload;
      state.currentStageId = action.payload.attributes.currentStage.id;
    });
    builder.addCase(fetchUserProject.fulfilled, (state, action) => {
      const serverAnswers: Record<
        string,
        {
          type: PAGE_ELEMENT;
          answer: UserAnswerAnswer;
        }
      > = {};
      if (!action.payload?.userAnswers) {
        state.answers = {};
        state.currentProject.userProject = initialState.currentProject.userProject;
        state.currentStageId = '';
        return;
      }
      action.payload.userAnswers.forEach((userAnswer) => {
        serverAnswers[userAnswer.attributes.pageElement.id] = {
          type: userAnswer.attributes.pageElement.attributes.elementType,
          answer: userAnswer.attributes.answer,
        };
      });
      state.currentProject.userProject = action.payload.userProject.data;
      state.currentStageId = action.payload.userProject.data.attributes.currentStage.id;
      state.answers = {
        ...state.answers,
        ...serverAnswers,
      };
    });
    builder.addCase(fetchCurrentProject.fulfilled, (state, action) => {
      state.currentProject.project = action.payload;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projects = action.payload.projects;
      state.totalProjects = action.payload.meta.total;
    });
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.currentProject.project = action.payload.data;
      state.previewProject = action.payload.data;
      state.answers = newAnswers(state, action.payload.data.id);
      state.answersBacklog = newBacklogAnswers(state, action.payload.data.id);
    });
    builder.addCase(submitAnswers.fulfilled, (state, action) => {
      state.currentProject.userProject = action.payload.userProject.data;
      state.currentProject.project = action.payload.project.data;
      state.currentProject.reviewStatus = action.payload.reviewStatus;
      state.currentStageId = action.payload.userProject.data.attributes.currentStage.id;
      state.activeStageId = action.payload.userProject.data.attributes.currentStage.id;
      state.currentPageId =
        action.payload.userProject.data.attributes.currentStage.id === state.currentPageId
          ? state.currentPageId
          : '';
    });
    builder.addCase(submitFeedback.fulfilled, () => {
      // do nothing
    });
    builder.addCase(submitEvaluationAnswers.fulfilled, (state, action) => {
      state.currentEvaluation.userEvaluation = action.payload;
    });
    builder.addCase(fetchUserEvaluation.fulfilled, (state, action) => {
      const serverAnswers: Record<
        string,
        {
          type: PAGE_ELEMENT;
          answer: UserAnswerAnswer;
        }
      > = {};
      action.payload.userAnswers.forEach((userAnswer) => {
        serverAnswers[userAnswer.attributes.pageElement.id] = {
          type: userAnswer.attributes.pageElement.attributes.elementType,
          answer: userAnswer.attributes.answer,
        };
      });
      state.currentEvaluation.userEvaluation = action.payload.userEvaluation.data;
      state.evaluationAnswers = {
        ...state.evaluationAnswers,
        ...serverAnswers,
      };
      state.currentEvaluationPageId =
        state.currentEvaluation.evaluation.attributes.pages[0].id || '';
    });
    builder.addCase(resetUserEvaluation.fulfilled, (state, action) => {
      state.currentEvaluation.userEvaluation = action.payload;
      state.evaluationAnswers = {};
      state.currentEvaluationPageId =
        state.currentEvaluation.evaluation.attributes.pages[0].id || '';
    });
    builder.addCase(fetchEvaluation.fulfilled, fetchEvaluationFulfilled);
    builder.addCase(fetchEvaluationByCode.fulfilled, fetchEvaluationFulfilled);
    builder.addCase(fetchUserAnswers.fulfilled, (state, action) => {
      state.currentUserAnswer = action.payload.userAnswer.data;
    });
    builder.addCase(resetUserProject.fulfilled, (state, action) => {
      state.currentProject.userProject = action.payload;
      state.currentStageId = action.payload.attributes.currentStage.id;
      state.answers = {};
      state.currentPageId = '';
      state.activeStageId = '';
      state.feedbacks = {};
      state.currentProject.reviewStatus = {};
    });
    builder.addCase(submitReview.fulfilled, (state, action) => {
      state.currentProject.reviewStatus = action.payload.reviewStatus;
      state.reviewing.reviewingUserAnswerId = '';
      state.reviewing.reviewingFrom = 'direct';
    });
    builder.addCase(submitReview.rejected, (_, action) => {
      action.meta.arg.errorCallback();
    });
    builder.addCase(fetchReviewable.fulfilled, (state, action) => {
      state.reviewable.items = action.payload.reviewableItems.data;
      state.reviewable.review = action.payload.review;
      state.reviewable.reviewPageElements = action.payload.reviewPageElements.data;
    });
    builder.addCase(fetchReviewable.rejected, (state) => {
      state.reviewable.items = [];
      state.reviewable.review = {};
      state.reviewable.reviewPageElements = [];
    });
    builder.addCase(fetchReviewStatus.fulfilled, (state, action) => {
      state.currentProject.reviewStatus = action.payload;
    });
    builder.addCase(fetchFinalSubmissions.fulfilled, (state, action) => {
      state.currentProject.finalSubmissions = action.payload.finalSubmissions.data;
    });
    builder.addCase(fetchIdeas.fulfilled, (state, action) => {
      state.currentProject.ideas = action.payload.ideas.data;
    });
  },
});

const { actions, reducer } = projectSlice;

export const {
  answerProject,
  answerEvaluation,
  nextValidate,
  updateFilters,
  togglePopupFlag,
  toggleVideoPopup,
  toggleInstructionPopup,
  toggleExamplePopup,
  toggleResourcesPopup,
  toggleNoteSidePane,
  toggleProjectHeaderPopup,
  switchProjectPage,
  switchEvaluationPage,
  switchProjectStage,
  switchProjectActiveStage,
  hideNotAnsweredModal,
  setRestrictions,
  updateRestrictions,
  resetProjectsFilters,
  resetEvaluationOrProject,
  updateReviewingFrom,
  setAllowCopyPaste,
} = actions;

export default reducer;
