import { Notification } from '@app/@types/redux/users';
import { fetchNotifications } from '@state/redux/slices/user.slice';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import classnames from 'classnames';
import moment from 'moment';
import { useEffect } from 'react';
import { FaBell, FaChevronUp } from 'react-icons/fa';
import { translate } from '../../../state/utils/helper';

import logger from '@libs/log';
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from '../Html';

function getIcon(event: string) {
  if (event === 'level_up') {
    return FaChevronUp;
  }
  return FaBell;
}

function generateNotification(notification: Notification) {
  const {
    attributes: { event, notifiableType, userChecked, createdAt, data },
  } = notification;
  const NotificationIcon = getIcon(event);
  return (
    <ListGroupItem
      className={classnames(
        userChecked ? 'notification-read' : 'notification-unread',
        'list-group-item-action'
      )}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
      }}
      tag="a"
    >
      <Row className="align-items-center">
        <Col className="col-auto">
          <NotificationIcon className="text-center" />
        </Col>
        <div className="col ml--2">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="mb-0 text-sm">{translate(`notifications.types.${notifiableType}`)}</h4>
            </div>
            <div className="text-right text-muted">
              <small>{moment(createdAt).fromNow()}</small>
            </div>
          </div>
          <p className="text-sm mb-0">
            {translate(`notifications.events.${event}`, {
              stage: data.projectStage || '',
              project: data.project || 'challenge',
            })}
          </p>
        </div>
      </Row>
    </ListGroupItem>
  );
}

const NotificationsAll = () => {
  const { notifications } = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchNotifications())
      .then(() => {
        logger.info('Notifications fetched');
      })
      .catch((error: unknown) => {
        logger.error(error);
      });
  }, [fetchNotifications]);

  return (
    <Row>
      <Col xs={12}>
        <h2 className="text-center my-5">All Notifications</h2>
      </Col>
      <Col sm={{ size: 6, offset: 3 }}>
        <Card>
          <CardBody>
            <ListGroup flush>
              {notifications.map((notification) => generateNotification(notification))}
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default NotificationsAll;
