import { LocationData } from '@app/@types/redux/auth';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setAccessToken, setRefreshToken } from '@state/utils/helper';
import { PURGE } from 'redux-persist';
import i18n from '../../../libs/i18n/I18n';
import Api, { OauthTokenResponse } from '../../utils/Api';

interface IAuthState {
  isSignedIn: boolean;
  previousLocation: LocationData;
  flags: {
    loginModal: boolean;
  };
}

const initialState: IAuthState = {
  isSignedIn: false,
  previousLocation: {
    search: '',
    pathname: '',
    hash: '',
    key: '',
  },
  flags: {
    loginModal: false,
  },
  // All your other state
};

/**
 * Thunk action to refresh the access token
 * @param refreshToken - The refresh token
 */
export const refresh = createAsyncThunk(
  'auth/refresh',
  async ({ refreshToken }: { refreshToken: string }, { dispatch }) => {
    const response = await Api.refresh({
      refreshToken,
    });
    if (response.data.accessToken) {
      setTokenHeaders(response.data.accessToken, response.data.refreshToken);
      await dispatch(fetchMe());
    }
    return response.data;
  }
);

/**
 * Thunk action to fetch the current user
 */
export const fetchMe = createAsyncThunk('auth/me', async () => {
  const response = await Api.me();
  return response.data.data;
});

/**
 * Thunk action to login after sign up
 * @param refreshToken - The refresh token
 */
export const loginAfterSignUp = createAsyncThunk(
  'auth/loginAfterSignUp',
  async ({ refreshToken }: { refreshToken: string }, { dispatch }) => {
    const refreshResponse = await Api.refresh({
      refreshToken,
    });
    if (refreshResponse.data.accessToken) {
      setTokenHeaders(refreshResponse.data.accessToken, refreshResponse.data.refreshToken);
      await dispatch(fetchMe());
    }
    return refreshResponse.data;
  }
);

/**
 * Thunk action to request a password reset token
 * @param email - The email address
 */
export const requestForgotPasswordToken = createAsyncThunk(
  'auth/requestForgotPasswordToken',
  async ({ email }: { email: string }) => {
    await Api.forgotPassword({ email });
  }
);

/**
 * Thunk action to request a magic login link
 * @param email - The email address
 * @param redirect - The redirect URL
 */
export const requestMagicLoginLink = createAsyncThunk(
  'auth/requestMagicLoginLink',
  async ({ email, redirect }: { email: string; redirect: string }) => {
    await Api.sendMagicLink({ email, redirect });
  }
);

/**
 * Thunk action to check if a password reset token is valid
 * @param token - The password reset token
 */
export const resetPasswordTokenValid = createAsyncThunk(
  'auth/resetPasswordTokenValid',
  async ({ token }: { token: string }) => {
    await Api.resetPasswordTokenValid({ token });
  }
);

/**
 * Thunk action to reset a password
 * @param token - The password reset token
 * @param password - The new password
 * @param passwordConfirmation - The new password confirmation
 */
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({
    token,
    password,
    passwordConfirmation,
  }: {
    token: string;
    password: string;
    passwordConfirmation: string;
  }) => {
    await Api.resetPassword({
      token,
      password,
      password_confirmation: passwordConfirmation,
    });
  }
);

/**
 * Thunk action to resend a confirmation mail
 * @param callback - The callback function
 */
export const resendConfirmationMail = createAsyncThunk(
  'auth/resendConfirmationMail',
  async ({ callback }: { callback: () => void }) => {
    await Api.resendConfirmationMail();
    callback();
  }
);

/**
 * Thunk action to login
 * @param email - The email address
 * @param password - The password
 */
export const login = createAsyncThunk(
  'auth/login',
  async (
    {
      email,
      password,
    }: {
      email: string;
      password: string;
    },
    { dispatch }
  ) => {
    const response = await Api.login({
      email,
      password,
    });
    if (response.data.accessToken) {
      setTokenHeaders(response.data.accessToken, response.data.refreshToken);
      await dispatch(fetchMe());
    }
    return response.data;
  }
);

/**
 * Thunk action to sign up
 * @param email - The email address
 * @param password - The password
 * @param confirmPassword - The password confirmation
 */
export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({
    email,
    password,
    confirmPassword,
  }: {
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    const response = await Api.signUp({
      email,
      password,
      password_confirmation: confirmPassword,
    });
    return response.data;
  }
);

/**
 * Thunk action to confirm an account
 * @param token - The confirmation token
 */
export const confirmAccount = createAsyncThunk(
  'auth/confirmAccount',
  async ({ token }: { token: string }) => {
    const response = await Api.confirmAccount({ token });
    return response.data.data;
  }
);

/**
 * Thunk action to verify a token
 * @param token - The token
 */
export const verifyToken = createAsyncThunk(
  'auth/verifyToken',
  async ({ token }: { token: string }) => {
    const response = await Api.verifyToken({ token });
    return response.data.data;
  }
);

/**
 * Thunk action to change the language
 * @param language - The language
 */
export const changeLanguage = createAsyncThunk('auth/changeLanguage', async (language: string) => {
  await i18n.changeLanguage(language);
  return language;
});

const setTokenHeaders = (accessToken: string, refreshToken: string) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

const refreshFulfilled = (state: IAuthState, action: PayloadAction<OauthTokenResponse>) => {
  const { accessToken, refreshToken } = action.payload;
  setTokenHeaders(accessToken, refreshToken);
  state.isSignedIn = true;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * Logs out the user
     * @param state - The current state
     */
    logoutUser(state: IAuthState) {
      state.isSignedIn = false;
      state = initialState;
    },

    updateLocation(state, action: PayloadAction<LocationData>) {
      state.previousLocation = action.payload;
    },

    toggleLoginModal(state, action: PayloadAction<boolean>) {
      state.flags.loginModal = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addCase(refresh.fulfilled, refreshFulfilled);
    builder.addCase(refresh.rejected, () => {
      // refreshFailure has no implementation
    });
    builder.addCase(loginAfterSignUp.fulfilled, refreshFulfilled);
    builder.addCase(loginAfterSignUp.rejected, () => {
      // refreshFailure has no implementation
    });
    builder.addCase(login.fulfilled, refreshFulfilled);
    builder.addCase(login.rejected, () => {
      // loginFailure has no implementation
    });
    builder.addCase(requestForgotPasswordToken.fulfilled, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(requestForgotPasswordToken.rejected, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(requestMagicLoginLink.fulfilled, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(requestMagicLoginLink.rejected, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(resetPasswordTokenValid.fulfilled, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(resetPasswordTokenValid.rejected, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(resetPassword.fulfilled, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(resetPassword.rejected, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(resendConfirmationMail.fulfilled, () => {
      // successToast(i18n.t("messages.success.confirmationMail"));
      // Added callback to the action creator
    });
    builder.addCase(resendConfirmationMail.rejected, () => {
      // errorToast(i18n.t("messages.error.default"));
      // Added callback to the action creator
    });
    builder.addCase(signUp.fulfilled, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(signUp.rejected, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(confirmAccount.fulfilled, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(confirmAccount.rejected, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(verifyToken.fulfilled, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(verifyToken.rejected, () => {
      // nothing to do here, putting it here for logs
    });
    builder.addCase(fetchMe.fulfilled, (_, action) => {
      const { attributes } = action.payload;
      if (attributes.preferences.language) {
        const language = attributes.preferences.language;
        localStorage.setItem('language', language);
        // dispatch(changeLanguage(language)); // TODO: Implement changeLanguage action
      }
    });
    builder.addCase(fetchMe.rejected, () => {
      // state.currentUser = initialState.currentUser;
    });
  },
});

export const { logoutUser, updateLocation, toggleLoginModal } = authSlice.actions;

export default authSlice.reducer;
