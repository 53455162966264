import i18n from '@libs/i18n/I18n';
import { BiNews } from 'react-icons/bi';
import { FaCubes, FaImages, FaMobileAlt, FaVideo } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

export interface SubmissionType {
  id: number;
  type: string;
  title: {
    label: string;
    placeholder: string;
  };
  link?: {
    label: string;
    placeholder: string;
  };
  optionBlock: {
    icon: IconType;
    heading: string;
    description: string;
    req: string;
  };
}

export const SUBMISSION_TYPES: SubmissionType[] = [
  {
    id: 1,
    type: 'video',
    title: {
      label: i18n.t('pages.prototypeMethods.video.title.label'),
      placeholder: i18n.t('pages.prototypeMethods.video.title.label'),
    },
    link: {
      label: i18n.t('pages.prototypeMethods.video.link.label'),
      placeholder: i18n.t('pages.prototypeMethods.video.link.label'),
    },
    optionBlock: {
      icon: FaVideo,
      heading: i18n.t('pages.prototypeMethods.video.optionBlock.heading'),
      description: i18n.t('pages.prototypeMethods.video.optionBlock.description'),
      req: i18n.t('pages.prototypeMethods.video.optionBlock.footer'),
    },
  },
  {
    id: 2,
    type: 'app',
    title: {
      label: i18n.t('pages.prototypeMethods.app.title.label'),
      placeholder: i18n.t('pages.prototypeMethods.app.title.label'),
    },
    link: {
      label: i18n.t('pages.prototypeMethods.app.link.label'),
      placeholder: i18n.t('pages.prototypeMethods.app.link.label'),
    },
    optionBlock: {
      icon: FaMobileAlt,
      heading: i18n.t('pages.prototypeMethods.app.optionBlock.heading'),
      description: i18n.t('pages.prototypeMethods.app.optionBlock.description'),
      req: i18n.t('pages.prototypeMethods.app.optionBlock.footer'),
    },
  },
  {
    id: 3,
    type: 'model',
    title: {
      label: i18n.t('pages.prototypeMethods.model.title.label'),
      placeholder: i18n.t('pages.prototypeMethods.model.title.label'),
    },
    optionBlock: {
      icon: FaCubes,
      heading: i18n.t('pages.prototypeMethods.model.optionBlock.heading'),
      description: i18n.t('pages.prototypeMethods.model.optionBlock.description'),
      req: i18n.t('pages.prototypeMethods.model.optionBlock.footer'),
    },
  },
  {
    id: 4,
    type: 'gallery',
    title: {
      label: i18n.t('pages.prototypeMethods.gallery.title.label'),
      placeholder: i18n.t('pages.prototypeMethods.gallery.title.label'),
    },
    link: {
      label: i18n.t('pages.prototypeMethods.gallery.link.label'),
      placeholder: i18n.t('pages.prototypeMethods.gallery.link.label'),
    },
    optionBlock: {
      icon: FaImages,
      heading: i18n.t('pages.prototypeMethods.gallery.optionBlock.heading'),
      description: i18n.t('pages.prototypeMethods.gallery.optionBlock.description'),
      req: i18n.t('pages.prototypeMethods.gallery.optionBlock.footer'),
    },
  },
  {
    id: 5,
    type: 'ad',
    title: {
      label: i18n.t('pages.prototypeMethods.ad.title.label'),
      placeholder: i18n.t('pages.prototypeMethods.ad.title.label'),
    },
    link: {
      label: i18n.t('pages.prototypeMethods.ad.link.label'),
      placeholder: i18n.t('pages.prototypeMethods.ad.link.label'),
    },
    optionBlock: {
      icon: BiNews,
      heading: i18n.t('pages.prototypeMethods.ad.optionBlock.heading'),
      description: i18n.t('pages.prototypeMethods.ad.optionBlock.description'),
      req: i18n.t('pages.prototypeMethods.ad.optionBlock.footer'),
    },
  },
  {
    id: 6,
    type: 'embed',
    title: {
      label: i18n.t('pages.prototypeMethods.embed.title.label'),
      placeholder: i18n.t('pages.prototypeMethods.embed.title.label'),
    },
    link: {
      label: i18n.t('pages.prototypeMethods.embed.link.label'),
      placeholder: i18n.t('pages.prototypeMethods.embed.link.label'),
    },
    optionBlock: {
      icon: BiNews,
      heading: i18n.t('pages.prototypeMethods.embed.optionBlock.heading'),
      description: i18n.t('pages.prototypeMethods.embed.optionBlock.description'),
      req: i18n.t('pages.prototypeMethods.embed.optionBlock.footer'),
    },
  },
  {
    id: 7,
    type: 'link',
    title: {
      label: i18n.t('pages.prototypeMethods.link.title.label'),
      placeholder: i18n.t('pages.prototypeMethods.ad.title.label'),
    },
    link: {
      label: i18n.t('pages.prototypeMethods.link.link.label'),
      placeholder: i18n.t('pages.prototypeMethods.link.link.label'),
    },
    optionBlock: {
      icon: BiNews,
      heading: i18n.t('pages.prototypeMethods.link.optionBlock.heading'),
      description: i18n.t('pages.prototypeMethods.link.optionBlock.description'),
      req: i18n.t('pages.prototypeMethods.link.optionBlock.footer'),
    },
  },
];

export const languages = [
  {
    code: 'en',
    flag: 'us',
    country: i18n.t('language.en'),
  },
];
