/* eslint-disable no-nested-ternary */
import classnames from 'classnames';

import { getDependeeValues, getPageElementDisabledState } from '@state/selectors/projects';
import { useState } from 'react';
import { CgCheckO, CgCloseO, CgRadioCheck } from 'react-icons/cg';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { Button, Input, Row } from 'reactstrap';
import { i18nCText } from '../../../../libs/i18n/I18n';

import { RadioAnswer } from '@app/@types/redux/answer';
import { RadioOptions } from '@app/@types/redux/pageElement';
import { PageElementProps } from '..';
import { translate } from '../../../../state/utils/helper';
import { Alert, Col, H2, Label } from '../../../UI/Html';
import './RadioButtons.scss';

const Radio: React.FC<PageElementProps> = (props) => {
  const { pageElement, answer, state } = props;
  const currentAnswer = props.currentAnswer as RadioAnswer | undefined;
  const { id, dependees } = pageElement.attributes;
  const peOptions = pageElement.attributes.options as RadioOptions;
  const disabled = getPageElementDisabledState(state, id);
  const {
    values,
    label,
    classes,
    type,
    recommendation = {
      correctAnswer: "That's correct!",
      incorrectAnswer:
        "Looks like you have selected an incorrect response. Don't worry, you can always master the concepts and retake the quiz",
    },
  } = peOptions;
  const textValue = currentAnswer?.value ?? '';
  const answered = Boolean(textValue);
  const [answerAlertFlag, setAnswerAlertFlag] = useState(false);
  const [answerObject, setAnswerObject] = useState<RadioAnswer>({
    value: '',
    isCorrect: false,
  });
  function radiobutton(text: string, index: number) {
    return (
      <div className="custom-control custom-radio mb-3 radioButtons" key={index}>
        <Input
          className="custom-control-input radio__input"
          name={`radio_${id}`}
          disabled={disabled}
          id={`radio_${id}_${String(index)}`}
          type="radio"
          autoComplete="off"
          checked={textValue === text}
          onClick={() => {
            answer({ value: text });
          }}
        />
        <Label
          className="custom-control-label radio__label"
          htmlFor={`radio_${id}_${String(index)}`}
        >
          {i18nCText(text)}
        </Label>
      </div>
    );
  }

  const options = getDependeeValues(dependees, values, state) as RadioOptions['values'];
  let output = <></>;
  switch (type) {
    case 'supervised_quiz': {
      const isCorrect = currentAnswer?.isCorrect ?? false;
      output = (
        <>
          <Alert
            isOpen={answerAlertFlag}
            onRequestClose={() => {
              setAnswerAlertFlag(false);
            }}
            showCloseButton
          >
            <Row className="pt-3">
              <Col>
                <h1 className="font-weight-500 text-dark">
                  {answerObject.isCorrect
                    ? translate('pageElements.imageCheckbox.style3.correct.title')
                    : translate('pageElements.imageCheckbox.style3.incorrect.title')}
                </h1>
              </Col>
            </Row>
            <Row className="pb-3 pt-2">
              <Col>
                <p className="font-weight-400 px-3 text-dark"></p>
              </Col>
            </Row>
            <Row>
              <>
                <Col className="text-sm text-center mb-2 mx-0 px-1">
                  <Button
                    color="info"
                    onClick={() => {
                      setAnswerAlertFlag(false);
                    }}
                  >
                    Got it!
                  </Button>
                </Col>
              </>
            </Row>
          </Alert>
          <Row>
            <Col md="12" className={classnames([classes, 'ml-md-3'])}>
              {label ? (
                <H2 className="font-weight-bolder text-black mb-4 mt-2 side-border-title">
                  {i18nCText(label)}
                </H2>
              ) : (
                ''
              )}
              {options.map((option, optionIndex) => {
                const text = option.value;
                const checked = textValue === text;
                return (
                  <div
                    key={optionIndex}
                    className="custom-control custom-radio radioButton__style_3_option"
                  >
                    <Input
                      className="custom-control-input"
                      name={`radio_${id}`}
                      id={`radio_${id}_${String(optionIndex)}`}
                      type="radio"
                      autoComplete="off"
                      checked={checked}
                      disabled={disabled || answered}
                      onClick={() => {
                        answer({ value: text, isCorrect: option.isCorrect });
                        setAnswerAlertFlag(true);
                        setAnswerObject(option);
                      }}
                    />
                    <label
                      className={classnames('custom-control-label', 'radioButton__style_3_label', {
                        'radioButton__style_3_label-selected': checked,
                        'radioButton__style_3_label-selected-incorrect':
                          checked && !option.isCorrect,
                      })}
                      htmlFor={`radio_${id}_${String(optionIndex)}`}
                    >
                      {checked ? option.isCorrect ? <CgCheckO /> : <CgCloseO /> : <CgRadioCheck />}
                      <h2 className="radioButton__style_3_text">{i18nCText(option.value)}</h2>
                    </label>
                  </div>
                );
              })}
              <br />
              <div className="d-flex">
                {answered && isCorrect ? (
                  <Alert className="d-flex align-items-center">
                    <FaThumbsUp className="mr-2" />
                    <span>{recommendation.correctAnswer}</span>
                  </Alert>
                ) : null}
                {answered && !isCorrect ? (
                  <Alert className="d-flex align-items-center">
                    <FaThumbsDown className="mr-2" />
                    <span>{recommendation.incorrectAnswer}</span>
                  </Alert>
                ) : null}
              </div>
            </Col>
          </Row>
        </>
      );
      break;
    }
    default: {
      output = (
        <Row>
          <Col md="12" className={classnames([classes, 'ml-md-3'])}>
            {label ? (
              <H2 className="font-weight-bolder text-black mb-4 mt-2 side-border-title">
                {i18nCText(label)}
              </H2>
            ) : (
              ''
            )}
            {options.map((item, key) => radiobutton(item.value, key))}
          </Col>
        </Row>
      );
      break;
    }
  }
  return output;
};

export default Radio;
