import { useAppDispatch, useAppSelector } from '@state/redux/store';
import classnames from 'classnames';
import 'flag-icon-css/css/flag-icon.min.css';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { languages } from '../../../libs/constants';
import { changeLanguage } from '../../../libs/helper';
import i18n from '../../../libs/i18n/I18n';
import { updatePreferences } from '../../../state/redux/slices/user.slice';
import { translate } from '../../../state/utils/helper';

import logger from '@libs/log';
import { DropdownItem, DropdownMenu, DropdownToggle, Media, UncontrolledDropdown } from '../Html';

interface ILanguageOptionsProps {
  mobile?: boolean;
  desktop?: boolean;
}
function LanguageOptions({ mobile, desktop }: ILanguageOptionsProps) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const paramLang = searchParams.get('lang');
  const reduxPreferences = useAppSelector((state) => state.users.preferences);

  const languageCountry = languages.find((item) => item.code === paramLang)?.country;

  useEffect(() => {
    if (paramLang && languageCountry) {
      localStorage.setItem('language', paramLang);
      changeLanguage(paramLang, languageCountry);
    }
  }, [paramLang, languageCountry]);
  function changeLang(code: string) {
    const tempLanguageCountry = languages.find((item) => item.code === code)?.country;
    if (tempLanguageCountry) {
      const json = {
        ...reduxPreferences,
      };
      json.language = code;
      dispatch(updatePreferences(json)).catch(() => {
        logger.error('');
      });
      localStorage.setItem('language', code);
      changeLanguage(code, tempLanguageCountry);
    }
  }
  const language = useAppSelector((state) => state.users.preferences.language || 'en');
  const sessionLanguage = localStorage.getItem('language') ?? false;
  const currentLanguage = languages.find(
    (item) => item.code === i18n.language || language || sessionLanguage
  );
  const isRTL = i18n.dir() === 'rtl';
  const dropdownMenu = (
    <DropdownMenu
      className={classnames('mt-2', {
        'text-right': isRTL,
      })}
    >
      {languages.map((item) => (
        <DropdownItem
          onClick={() => {
            changeLang(item.code);
          }}
          to="#"
          key={item.code}
          tag={Link}
        >
          <span className={`flag-icon flag-icon-${item.flag}`} />
          <span className="mx-2">{item.country}</span>
        </DropdownItem>
      ))}
    </DropdownMenu>
  );
  if (mobile) {
    return (
      <UncontrolledDropdown nav inNavbar className="d-md-none my-auto">
        <DropdownToggle nav>
          <Media className="align-items-center text-sm font-weight-bold">
            {currentLanguage ? (
              <span className={`flag-icon flag-icon-${currentLanguage.flag}`} />
            ) : (
              translate('layout.navbar.language')
            )}
          </Media>
        </DropdownToggle>
        {dropdownMenu}
      </UncontrolledDropdown>
    );
  }
  if (desktop) {
    return (
      <UncontrolledDropdown nav inNavbar className="d-none d-md-block my-auto">
        <DropdownToggle nav>
          <Media className={classnames('align-items-center text-sm font-weight-bold')}>
            {currentLanguage ? (
              <>
                <span className={`flag-icon flag-icon-${currentLanguage.flag}`} />
                <span className="mx-2">{currentLanguage.country}</span>
              </>
            ) : (
              translate('layout.navbar.language')
            )}
          </Media>
        </DropdownToggle>
        {dropdownMenu}
      </UncontrolledDropdown>
    );
  }
  return <></>;
}

export default LanguageOptions;
