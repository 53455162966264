import classnames from 'classnames';

import { UserPreferences } from '@app/@types/redux/users';
import logger from '@libs/log';
import { useAppDispatch, useAppSelector } from '@state/redux/store';
import { isLoggedIn } from '@utils/helpers/auth';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiCheck, FiUser } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { Input } from 'reactstrap';
import binoculars from '../../../assets/images/icons/binoculars.svg';
import keyword from '../../../assets/images/icons/user.svg';
import { languages } from '../../../libs/constants';
import { changeLanguage } from '../../../libs/helper';
import { redirectTo } from '../../../routes/helpers';
import { updateLocation } from '../../../state/redux/slices/auth.slice';
import { updateAccountSettings, updatePreferences } from '../../../state/redux/slices/user.slice';
import Api from '../../../state/utils/Api';
import { translate } from '../../../state/utils/helper';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  H1,
  ImagePlaceholder,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  SubmitButton,
} from '../Html';

function UserNotificationModal() {
  const isSignedIn = isLoggedIn();
  const reduxPreferences = useAppSelector((state) => state.users.preferences);
  const [preferences, setPreferences] = useState<UserPreferences>(reduxPreferences);
  const [alert, setAlert] = useState('');
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (isSignedIn) {
      Api.getPreferences()
        .then((data) => {
          const resPreferences = data.data;
          setPreferences(resPreferences);
        })
        .catch(() => {
          logger.error('Error fetching preferences');
        });
    }
  }, [isSignedIn]);

  const language = preferences.language;
  const [languageOption, setLanguageOption] = useState({
    ..._.find(languages, {
      code: language,
    }),
  });

  useEffect(() => {
    setLanguageOption({
      ..._.find(languages, {
        code: language,
      }),
    });
  }, [language]);

  const mbtiModalOnClose = (result: boolean) => {
    setAlert('');
    const json: UserPreferences = {
      ...preferences,
    };
    json.showWelcomeModal = false;
    Api.updatePreferences({ preferences: json })
      .then((data) => {
        const resPreferences = data.data;
        setPreferences(resPreferences);
        if (result) {
          dispatch(updateLocation(location));
          redirectTo('/evaluations/b5');
        }
      })
      .catch(() => {
        logger.error('Error updating preferences');
      });
  };
  // Initialize react-hook-form components and methods
  const { handleSubmit } = useForm({
    mode: 'onChange',
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  // Submit form handle
  const [name, setName] = useState('');

  const onSubmit = () => {
    setSubmitLoading(true);
    const code = String(languageOption.code);
    const json: UserPreferences = {
      ...preferences,
    };
    json.language = String(code);
    json.showLanguageSelect = false;
    const tempLanguageCountry = String(languageOption.country);
    // const tempLanguageCountry = (_.find(languages, { code }) || {}).country;
    dispatch(updatePreferences(json)).catch(() => {
      logger.error('Error updating preferences');
      setSubmitLoading(false);
    });
    dispatch(updateAccountSettings({ firstName: name })).catch(() => {
      logger.error('Error updating user name');
      setSubmitLoading(false);
    });
    localStorage.setItem('language', String(code));
    changeLanguage(String(code), tempLanguageCountry);
    setAlert('');
    setSubmitLoading(false);
  };

  const [focusName, setFocusName] = useState(false);

  useEffect(() => {
    if (preferences.showWelcomeModal) {
      setAlert('mbti');
    }
    if (preferences.showLanguageSelect) {
      setAlert('changeLanguage');
    }
  }, [preferences]);
  return (
    <>
      <Alert
        isOpen={alert === 'mbti'}
        onRequestClose={() => {
          mbtiModalOnClose(false);
        }}
        showCloseButton
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={binoculars} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">
              {translate('userNotificationModal.mbtiAssessment.heading')}
            </H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-3">
          <Col>
            <p className="px-3 font-weight-400 text-dark">
              {translate('userNotificationModal.mbtiAssessment.description')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="info"
              onClick={() => {
                mbtiModalOnClose(true);
              }}
            >
              {translate('userNotificationModal.mbtiAssessment.button')}
            </Button>
          </Col>
        </Row>
      </Alert>
      <Alert
        isOpen={alert === 'changeLanguage'}
        onRequestClose={() => {
          onSubmit();
        }}
        showCloseButton
      >
        <Row className="py-3">
          <Col>
            <ImagePlaceholder src={keyword} height="60" width="60" native />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <H1 className="font-weight-500 text-dark">
              {translate('userNotificationModal.changeLanguage.heading')}
            </H1>
          </Col>
        </Row>
        <Row className="pt-2 pb-0">
          <Col>
            <p className="px-3 font-weight-400 text-dark">
              {translate('userNotificationModal.changeLanguage.subheading')}
            </p>
          </Col>
        </Row>
        <Row className="py-2">
          <Col>
            <Form
              role="form"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="edit-profile"
            >
              <Row className="justify-content-md-center">
                <Col md={8}>
                  <FormGroup>
                    <InputGroup
                      className={classnames('input-group-merge', {
                        focused: focusName,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FiUser size={20} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={translate('editProfile.placeholder.firstName')}
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={name}
                        onChange={(e: string) => {
                          setName(e);
                        }}
                        required
                        autoComplete="firstName"
                        onFocus={() => {
                          setFocusName(true);
                        }}
                        onBlur={() => {
                          setFocusName(false);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center">
                    <SubmitButton
                      className="mt-2 btn-icon btn-3 btn-round"
                      color="primary"
                      type="submit"
                      loading={submitLoading}
                    >
                      <span className="btn-inner--icon">
                        {!submitLoading && <FiCheck size={20} />}
                      </span>
                      <span className="btn-inner--text">
                        {translate('userNotificationModal.changeLanguage.saveLanguageBtn')}
                      </span>
                    </SubmitButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Alert>
    </>
  );
}

export default UserNotificationModal;
