import { Component } from 'react';
import { error } from '../../assets/images';
import Layout from '../../components/UI/Layout';
import ErrorPage from '../../components/UI/Layout/ErrorPage';
import { translate } from '../../state/utils/helper';

class C404 extends Component {
  override render() {
    return (
      <Layout>
        <ErrorPage errorMessage={translate('errors.notFound')} errorImage={error[404]} />
      </Layout>
    );
  }
}

export default C404;
